import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import Main from "../../layout/Main";
import Button from "../../components/Button";
import NwIllustration from "../../assets/icons/nw-illustration.svg"
import NwMap from "../../assets/icons/nw-map.svg"
// import NwCoatofarm from "../../assets/imgs/nw-coatofarm.png"
// import NwNibss from "../../assets/imgs/nw-nibss.png"
// import Nwzenith from "../../assets/imgs/nw-zenith.png"
// import NwWemabank from "../../assets/imgs/nw-wemabank.png"
import NwPayfixy from "../../assets/imgs/nw-payfixy.png"
import NwNewEra from "../../assets/imgs/nw-new-era.png"
// import NwGtb from "../../assets/imgs/nw-gtb.png"
// import NwSterling from "../../assets/imgs/nw-sterling.png"
// import NwAccess from "../../assets/imgs/nw-access.png"
import NwSwg from "../../assets/imgs/nw-swg.png"
// import NwPaycenter from "../../assets/imgs/nw-paycenter.png"
// import NwFlutterwave from "../../assets/imgs/nw-flutterwave.png"
import NwAward from "../../assets/icons/nw-award.svg"
import NwUsers from "../../assets/icons/nw-users.svg"
import NwAnalytics from "../../assets/imgs/nw-analytics.png"
import NwOis from "../../assets/icons/nw-ois.svg"
import NwNetpostSvg from "../../assets/icons/nw-netpost.svg"
import NwInnovate from "../../assets/icons/nw-innovate.svg"
import NwAutomation from "../../assets/imgs/nw-automation.png"
import NwSecurity from "../../assets/imgs/nw-security.png"
import NwSustainability from "../../assets/imgs/nw-sustainability.png"
import NwInfrastructure from "../../assets/imgs/nw-infrastructure.png"
import NwTechInnovation from "../../assets/imgs/nw-tech-innovation.png"
import NwCustomerExperience from "../../assets/imgs/nw-customer-experience.png"
import NwIV from "../../assets/icons/nw-iv.svg"
import NwCDA from "../../assets/icons/nw-cda.svg"
import NwCM from "../../assets/icons/nw-cm.svg"
import NwHRM from "../../assets/icons/nw-hrm.svg"
import NwRE from "../../assets/imgs/nw-re.png"
import NwSAD from "../../assets/icons/nw-sad.svg"
// import NwIM from "../../assets/icons/nw-im.svg"
import NwLG from "../../assets/icons/nw-lg.svg"
import NwED from "../../assets/icons/nw-ed.svg"
import NwBA from "../../assets/icons/nw-ba.svg"
import NwHC from "../../assets/icons/nw-hc.svg"
// import NwFI from "../../assets/icons/nw-fi.svg"
import NwBI from "../../assets/icons/nw-bi.svg"
import NwPF from "../../assets/icons/nw-pf.svg"
import NwPVI from "../../assets/icons/nw-pvi.svg"
import NwBMM from "../../assets/icons/nw-bmm.svg"
import NwCloud from "../../assets/imgs/nw-cloud.png"
import NwCart from "../../assets/imgs/nw-cart.png"
import NwTag from "../../assets/imgs/nw-tag.png"
import NwPeople from "../../assets/imgs/nw-people.png"
import NwLocation from "../../assets/imgs/nw-location.png"
import NwCorporate from "../../assets/imgs/nw-corporate.png"
import NwData from "../../assets/imgs/nw-data.png"
import NwIdentity from "../../assets/imgs/nw-identity.png"
import NwReg from "../../assets/imgs/nw-reg.png"
import NwFi from "../../assets/imgs/nw-fi.png"
import NwBm from "../../assets/imgs/nw-bm.png"
import "./styles.scss";
import { useLocation } from "react-router-dom";
import { WHY_CHOOSE_US } from "../../utils/constants";
import { Modal } from "antd";
import GetStarted from "../../components/GetStarted";

const Home = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const content = (
    <GetStarted
      handleClose={() => handleOpenChange(false)}
    />
  );

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.hash]);

  return (
    <Main>
      <Helmet>
        <title>Home | Newworks</title>
        <meta name="keywords" content="New Era" />
      </Helmet>
      <div className={"min-h-screen h-full overflow-x-hidden"}>
        <section className="sm:pt-32 pt-24 container" id="home">
          <div className="flex flex-wrap">
            <div className="md:w-1/2 w-full">
              <h4 className="xl:text-heading-xl lg:text-heading-lg md:text-heading-sm md:font-bold text-heading-xs font-bold md:pr-0 pr-10">
                <span className="text-main-color font-euclidBold">Empowering African Institutions </span>
                <span className="text-black font-euclidBold">with Technological Solutions</span>
                {/* <span className="text-primary-500 font-euclidBold">.</span> */}
              </h4>
            </div>
            <div className="md:w-1/2 xl:pl-[17rem] lg:pl-[10rem] md:pl-[5rem] w-full md:mt-0 mt-10">
              <p>Welcome to Newworks Solution, where we enrich your business with cutting-edge technology to improve your productivity, develop and build your database and grow your sales.</p>
              <div className="mt-10">
                <Button
                  state={"SECONDARY"}
                  text={"Get Started"}
                  onClick={() => handleOpenChange(true)}
                  type={"button"}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="bg-black md:h-[34rem] h-[50rem] mt-20 overflow-hidden">
          <div className="container h-full">
            <div className="flex flex-wrap w-full h-full">
              <div className="md:hidden block w-full relative">
                <img src={NwIllustration} alt="NwIllustration" className="absolute right-0 md:my-auto top-0 bottom-0 hover:scale-150 duration-300 transition-all cursor-pointer" />
              </div>
              <div className="md:w-1/2 w-full flex items-center md:pt-0 pt-20">
                <h5 className="text-white lg:text-heading-sm text-heading-xs font-medium xl:pr-[16rem] lg:pr-[10rem] pr-[5rem] font-euclidMedium">Efficient operations through seamless, strategic innovations</h5>
              </div>
              <div className="md:block hidden w-1/2 relative">
                <img src={NwIllustration} alt="NwIllustration" className="absolute right-0 my-auto top-0 bottom-0 hover:scale-150 duration-300 transition-all cursor-pointer" />
              </div>
            </div>
          </div>
        </section>
        {/* <section className="bg-gray-400 py-20">
          <h5 className="font-normal text-center tracking-[8px]">WE PROVIDE SOLUTIONS FOR CREDIBLE ORGANISATIONS</h5>
          <div className="flex w-full h-full overflow-x-auto gap-x-12 px-10 scrollable-icons justify-center">
            <img src={NwNetpostSvg} alt="NwNetpostSvg" className="h-10 hover:scale-150 hover:scale-150 duration-300 transition-all cursor-pointer" />
            <img src={NwCoatofarm} alt="NwCoatofarm" className="h-10 hover:scale-150 hover:scale-150 duration-300 transition-all cursor-pointer" />
            <img src={NwNibss} alt="NwNibss" className="h-10 hover:scale-150 hover:scale-150 duration-300 transition-all cursor-pointer" />
            <img src={Nwzenith} alt="Nwzenith" className="h-10 hover:scale-150 hover:scale-150 duration-300 transition-all cursor-pointer" />
            <img src={NwWemabank} alt="NwWemabank" className="h-10 hover:scale-150 hover:scale-150 duration-300 transition-all cursor-pointer" />
            <img src={NwGtb} alt="NwGtb" className="h-10 hover:scale-150 hover:scale-150 duration-300 transition-all cursor-pointer" />
            <img src={NwSterling} alt="NwSterling" className="h-10 hover:scale-150 hover:scale-150 duration-300 transition-all cursor-pointer" />
            <img src={NwAccess} alt="NwAccess" className="h-10 hover:scale-150 hover:scale-150 duration-300 transition-all cursor-pointer" />
            <img src={NwSwg} alt="NwSwg" className="h-10 hover:scale-150 hover:scale-150 duration-300 transition-all cursor-pointer" />
            <img src={NwPaycenter} alt="NwPaycenter" className="h-10 hover:scale-150 hover:scale-150 duration-300 transition-all cursor-pointer" />
            <img src={NwFlutterwave} alt="NwFlutterwave" className="h-10 hover:scale-150 hover:scale-150 duration-300 transition-all cursor-pointer" />
          </div>
        </section> */}
        <section className="py-20 container">
          <div className="flex flex-wrap md:flex-row flex-col flex-col-reverse">
            <div className="md:w-7/12 w-full">
              <h4 className="xl:text-heading-xl lg:text-heading-lg text-heading-sm font-bold font-euclidBold">
                We make significant difference to your business
              </h4>
              <div className="w-full mt-4">
                <div className="w-4/12 border-[0.8px] border-t-black"></div>
              </div>
              <div className="w-full mt-10 flex flex-wrap">
                <div className="md:w-1/2 w-full md:mt-0 mt-10">
                  <img src={NwAward} alt="NwAward" />
                  <h4 className="xl:text-heading-xl lg:text-heading-lg md:text-heading-sm md:font-bold text-heading-xl text-main-color font-euclidBold">
                    1,000 +
                  </h4>
                  <p className="md:text-body-lg text-body-xl">Organisations and businesses</p>
                </div>
                <div className="md:w-1/2 w-full md:mt-0 mt-20">
                  <img src={NwUsers} alt="NwUsers" />
                  <h4 className="xl:text-heading-xl lg:text-heading-lg md:text-heading-sm md:font-bold text-heading-xl text-main-color font-euclidBold">
                    1 Million
                  </h4>
                  <p className="md:text-body-lg text-body-xl">Users worldwise</p>
                </div>
              </div>
              <h4 className="mt-10">
                <span className="xl:text-heading-xl lg:text-heading-lg text-heading-sm font-bold font-euclidBold">With notable presence in </span>
                <br className="xl:block hidden" />
                <span className="xl:text-heading-xl lg:text-heading-lg text-heading-sm font-bold font-euclidBold text-main-color">5 global locations</span>
              </h4>
            </div>
            <div className="md:w-5/12 w-full">
              <img src={NwMap} alt="NwMap" className="h-full" />
            </div>
          </div>
        </section>
        <section className="bg-black md:h-[983px]" id="servicesAndProducts">
          <div className="container h-full md:py-0 py-10">
            <div className="flex flex-wrap w-full h-full">
              <div className="md:w-1/2 w-full flex flex-col justify-center pr-[10rem] md:pt-0 pt-16">
                <h4 className="xl:text-heading-xl lg:text-heading-lg text-heading-sm font-bold font-euclidBold">
                  <span className="text-white">Our </span>
                  <span className="text-secondary-500">Products and Services</span>
                </h4>
                <p className="text-white mt-6 md:hidden block">We've got you covered</p>
                <p className="text-white mt-6 md:block hidden">We've got you covered</p>
                <div className="md:mt-10 md:mb-0 mt-16 mb-16">
                  <Button
                    state={"WHITE"}
                    text={"Get Started"}
                    onClick={() => handleOpenChange(true)}
                    type={"button"}
                  />
                </div>
              </div>
              <div className="md:w-1/2 w-full items-space h-full">
                <div className="md:h-[342px] items item-one cursor-pointer relative">
                  <img className="item-img h-20" src={NwCloud} alt="NwCloud" />
                  <p className="text-body-xl text-white item-body w-[94%]">We create custom software solutions that are specifically designed to meet the unique needs of your business. These tailor-made applications will enhance your productivity and help you achieve your goals more efficiently.</p>
                  <p className="xl:text-body-2xl lg:text-body-xl text-body-lg text-body-xl text-white item-title">Bespoke application softwares</p>
                  <div className="item-button">
                    <div className="blank-btn border border-white rounded-full h-12 w-12 flex items-center justify-center text-white">
                      <span className="arrowc"></span>
                    </div>
                    <div className="full-btn">
                      <Button
                        state={"PRIMARY"}
                        text={"Get Started"}
                        onClick={() => handleOpenChange(true)}
                        type={"button"}
                      />
                    </div>
                  </div>
                </div>
                <div className="h-[342px] items item-two cursor-pointer relative">
                  <img className="item-img h-20" src={NwCart} alt="NwCart" />
                  <p className="text-body-xl text-white item-body w-[94%]">Whether you're running an online store or conducting business transactions over the internet, we provide the tools and services to help you succeed in the digital marketplace. From setting up e-commerce platforms to integrating secure payment gateways, we support organisations’ digital business growth.</p>
                  <p className="xl:text-body-2xl lg:text-body-xl text-body-lg text-white item-title lg:w-full w-[75%]">Digital Commerce</p>
                  <div className="item-button">
                    <div className="blank-btn border border-white rounded-full h-12 w-12 flex items-center justify-center text-white">
                      <span className="arrowc"></span>
                    </div>
                    <div className="full-btn">
                      <Button
                        state={"PRIMARY"}
                        text={"Get Started"}
                        onClick={() => handleOpenChange(true)}
                        type={"button"}
                      />
                    </div>
                  </div>
                </div>
                <div className="h-[342px] items item-three cursor-pointer relative">
                  <img className="item-img h-20" src={NwTag} alt="NwTag" />
                  <p className="text-body-xl text-white item-body w-[94%]">Say goodbye to manual paperwork and hello to streamlined efficiency. Our e-procurement system simplifies and automates the process of requisitions and purchase orders, making it easier for your business to manage procurement activities with transparency and speed.</p>
                  <p className="xl:text-body-2xl lg:text-body-xl text-body-lg text-white item-title lg:w-full w-[75%]">E-Procurement System</p>
                  <div className="item-button">
                    <div className="blank-btn border border-white rounded-full h-12 w-12 flex items-center justify-center text-white">
                      <span className="arrowc"></span>
                    </div>
                    <div className="full-btn">
                      <Button
                        state={"PRIMARY"}
                        text={"Get Started"}
                        onClick={() => handleOpenChange(true)}
                        type={"button"}
                      />
                    </div>
                  </div>
                </div>
                <div className="h-[342px] items item-four cursor-pointer relative">
                  <img className="item-img h-20" src={NwPeople} alt="NwPeople" />
                  <p className="text-body-xl text-white item-body w-[94%]">Managing HR processes can be time-consuming, but with our HR management system, it becomes a breeze. From maintaining employee records to handling payroll management, our software simplifies and automates various HR tasks, freeing up your time for more strategic activities.</p>
                  <p className="xl:text-body-2xl lg:text-body-xl text-body-lg text-white item-title lg:w-full w-[75%]">Human Resources Management Information</p>
                  <div className="item-button">
                    <div className="blank-btn border border-white rounded-full h-12 w-12 flex items-center justify-center text-white">
                      <span className="arrowc"></span>
                    </div>
                    <div className="full-btn">
                      <Button
                        state={"PRIMARY"}
                        text={"Get Started"}
                        onClick={() => handleOpenChange(true)}
                        type={"button"}
                      />
                    </div>
                  </div>
                </div>
                <div className="h-[342px] items item-five cursor-pointer relative">
                  <img className="item-img h-20" src={NwLocation} alt="NwLocation" />
                  <p className="text-body-xl text-white item-body w-[94%]">Navigating immigration processes can be complex, but our immigration management system makes it easier. From securely managing visa applications to tracking and ensuring compliance, our software simplies the entire immigration process, saving you time and effort.</p>
                  <p className="xl:text-body-2xl lg:text-body-xl text-body-lg text-white item-title lg:w-full w-[75%]">Immigration Management System</p>
                  <div className="item-button">
                    <div className="blank-btn border border-white rounded-full h-12 w-12 flex items-center justify-center text-white">
                      <span className="arrowc"></span>
                    </div>
                    <div className="full-btn">
                      <Button
                        state={"PRIMARY"}
                        text={"Get Started"}
                        onClick={() => handleOpenChange(true)}
                        type={"button"}
                      />
                    </div>
                  </div>
                </div>
                <div className="h-[342px] items item-six cursor-pointer relative">
                  <img className="item-img h-20" src={NwCorporate} alt="NwCorporate" />
                  <p className="text-body-xl text-white item-body w-[94%]">Our team of expert IT consultants is here to provide organisations with strategic guidance and innovative solutions. We understand business objectives and tailor our consulting services to help you leverage technology effectively, improving business operations and achieving set  goals.</p>
                  <p className="xl:text-body-2xl lg:text-body-xl text-body-lg text-white item-title lg:w-full w-[75%]">IT Consulting</p>
                  <div className="item-button">
                    <div className="blank-btn border border-white rounded-full h-12 w-12 flex items-center justify-center text-white">
                      <span className="arrowc"></span>
                    </div>
                    <div className="full-btn">
                      <Button
                        state={"PRIMARY"}
                        text={"Get Started"}
                        onClick={() => handleOpenChange(true)}
                        type={"button"}
                      />
                    </div>
                  </div>
                </div>
                <div className="h-[342px] items item-seven cursor-pointer relative">
                  <img className="item-img h-20" src={NwData} alt="NwData" />
                  <p className="text-body-xl text-white item-body w-[94%]">Storing, managing, and analysing large amounts of information can be challenging, but our national database system handles it seamlessly. With our secure and efficient software, organisations can store and access vast amounts of data, enabling informed decision-making and efficient data management.</p>
                  <p className="xl:text-body-2xl lg:text-body-xl text-body-lg text-white item-title lg:w-full w-[75%]">National Databank System</p>
                  <div className="item-button">
                    <div className="blank-btn border border-white rounded-full h-12 w-12 flex items-center justify-center text-white">
                      <span className="arrowc"></span>
                    </div>
                    <div className="full-btn">
                      <Button
                        state={"PRIMARY"}
                        text={"Get Started"}
                        onClick={() => handleOpenChange(true)}
                        type={"button"}
                      />
                    </div>
                  </div>
                </div>
                <div className="h-[342px] items item-eight cursor-pointer relative">
                  <img className="item-img h-16" src={NwIdentity} alt="NwIdentity" />
                  <p className="text-body-xl text-white item-body w-[94%]">Ensuring the integrity and privacy of citizen's identities is crucial, and our software is designed to do just that. Our national identity management system securely and accurately manages citizen information, protecting data privacy while maintaining the accuracy and reliability of identity records.</p>
                  <p className="xl:text-body-2xl lg:text-body-xl text-body-lg text-white item-title lg:w-full w-[75%]">National Identity Management</p>
                  <div className="item-button">
                    <div className="blank-btn border border-white rounded-full h-12 w-12 flex items-center justify-center text-white">
                      <span className="arrowc"></span>
                    </div>
                    <div className="full-btn">
                      <Button
                        state={"PRIMARY"}
                        text={"Get Started"}
                        onClick={() => handleOpenChange(true)}
                        type={"button"}
                      />
                    </div>
                  </div>
                </div>
                <div className="h-[342px] items item-nine cursor-pointer relative">
                  <img className="item-img h-16" src={NwReg} alt="NwReg" />
                  <p className="text-body-xl text-white item-body w-[94%]">Simplify the process of National Identification Number (NIN) registration with our user-friendly solution. Our software streamlines the registration and validation process, making it easier for individuals to obtain their NINs and for authorities to manage the identification system efficiently.</p>
                  <p className="xl:text-body-2xl lg:text-body-xl text-body-lg text-white item-title lg:w-full w-[75%]">NIN Registration</p>
                  <div className="item-button">
                    <div className="blank-btn border border-white rounded-full h-12 w-12 flex items-center justify-center text-white">
                      <span className="arrowc"></span>
                    </div>
                    <div className="full-btn">
                      <Button
                        state={"PRIMARY"}
                        text={"Get Started"}
                        onClick={() => handleOpenChange(true)}
                        type={"button"}
                      />
                    </div>
                  </div>
                </div>
                <div className="h-[342px] items item-ten cursor-pointer relative">
                  <img className="item-img h-16" src={NwFi} alt="NwFi" />
                  <p className="text-body-xl text-white item-body w-[94%]">Need funding for long-term business projects or public infrastructure initiatives? We offer financing solutions for businesses, organisations and industrial projects, helping you secure the necessary capital to bring your projects to life.</p>
                  <p className="xl:text-body-2xl lg:text-body-xl text-body-lg text-white item-title lg:w-full w-[75%]">Business Investment & Project Financing</p>
                  <div className="item-button">
                    <div className="blank-btn border border-white rounded-full h-12 w-12 flex items-center justify-center text-white">
                      <span className="arrowc"></span>
                    </div>
                    <div className="full-btn">
                      <Button
                        state={"PRIMARY"}
                        text={"Get Started"}
                        onClick={() => handleOpenChange(true)}
                        type={"button"}
                      />
                    </div>
                  </div>
                </div>
                <div className="h-[342px] items item-eleven cursor-pointer relative !mb-0">
                  <img className="item-img h-16" src={NwBm} alt="NwBm" />
                  <p className="text-body-xl text-white item-body w-[94%]">Managing passport issuance, visas, and visa-on-arrival processes for non-nationals can be complex, but our border management system simplifies the entire process. With our software, you can securely manage border control activities, ensuring efficient processing and enhanced security measures.</p>
                  <p className="xl:text-body-2xl lg:text-body-xl text-body-lg text-white item-title lg:w-full w-[75%]">Border Management</p>
                  <div className="item-button">
                    <div className="blank-btn border border-white rounded-full h-12 w-12 flex items-center justify-center text-white">
                      <span className="arrowc"></span>
                    </div>
                    <div className="full-btn">
                      <Button
                        state={"PRIMARY"}
                        text={"Get Started"}
                        onClick={() => handleOpenChange(true)}
                        type={"button"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="md:py-20 py-16 container" id="industries">
          <div className="flex flex-wrap">
            <div className="w-full">
              <h4 className="xl:text-heading-xl lg:text-heading-lg md:text-heading-sm md:font-bold text-heading-xs font-bold text-center font-euclidBold">
                Our <span className="text-main-color font-euclidBold">Operations</span>
              </h4>
              <div className="md:flex hidden w-full my-10 justify-center">
                <div className="w-8/12">
                  <img src={NwAnalytics} alt="NwAnalytics" className="" />
                </div>
              </div>
              <div className="md:hidden flex flex-wrap gap-y-10 w-full my-10 justify-between px-4">
                <div className="w-1/2 flex items-center justify-center flex-col">
                  <img src={NwIV} alt="NwIV" className="" />
                  <p className="mt-4 text-center">Identity<br />Verification</p>
                </div>
                <div className="w-1/2 flex items-center justify-center flex-col">
                  <img src={NwCDA} alt="NwCDA" className="" />
                  <p className="mt-4 text-center">Cybersecurity<br />and Data Analytics</p>
                </div>
                <div className="w-1/2 flex items-center justify-center flex-col">
                  <img src={NwCM} alt="NwCM" className="" />
                  <p className="mt-4 text-center">Construction and<br />Manufacturing</p>
                </div>
                <div className="w-1/2 flex items-center justify-center flex-col">
                  <img src={NwHRM} alt="NwHRM" className="" />
                  <p className="mt-4 text-center">Human Resource<br />Management</p>
                </div>
                <div className="w-1/2 flex items-center justify-center flex-col">
                  <img src={NwRE} alt="NwRE" className="h-14" />
                  <p className="mt-4 text-center">Retail and<br />E-commerce</p>
                </div>
                <div className="w-1/2 flex items-center justify-center flex-col">
                  <img src={NwSAD} alt="NwSAD" className="" />
                  <p className="mt-4 text-center">Software and<br />Application<br />Development</p>
                </div>
                {/* <div className="w-1/2 flex items-center justify-center flex-col">
                  <img src={NwIM} alt="NwIM" className="" />
                  <p className="mt-4 text-center">Immigration</p>
                </div> */}
                <div className="w-1/2 flex items-center justify-center flex-col">
                  <img src={NwLG} alt="NwLG" className="" />
                  <p className="mt-4 text-center">Logistics</p>
                </div>
                <div className="w-1/2 flex items-center justify-center flex-col">
                  <img src={NwED} alt="NwED" className="" />
                  <p className="mt-4 text-center">Education</p>
                </div>
                <div className="w-1/2 flex items-center justify-center flex-col">
                  <img src={NwBA} alt="NwBA" className="" />
                  <p className="mt-4 text-center">Biometric<br />Authentication</p>
                </div>
                <div className="w-1/2 flex items-center justify-center flex-col">
                  <img src={NwHC} alt="NwHC" className="" />
                  <p className="mt-4 text-center">Health Care</p>
                </div>
                {/* <div className="w-1/2 flex items-center justify-center flex-col">
                  <img src={NwFI} alt="NwFI" className="" />
                  <p className="mt-4 text-center">Finance</p>
                </div> */}
                <div className="w-1/2 flex items-center justify-center flex-col">
                  <img src={NwBI} alt="NwBI" className="" />
                  <p className="mt-4 text-center">Business<br />Investment</p>
                </div>
                <div className="w-1/2 flex items-center justify-center flex-col">
                  <img src={NwPF} alt="NwPF" className="" />
                  <p className="mt-4 text-center">Project<br />Financing</p>
                </div>
                <div className="w-1/2 flex items-center justify-center flex-col">
                  <img src={NwPVI} alt="NwPVI" className="" />
                  <p className="mt-4 text-center">Passport & Visa<br />Issuance</p>
                </div>
                <div className="w-1/2 flex items-center justify-center flex-col">
                  <img src={NwBMM} alt="NwBMM" className="" />
                  <p className="mt-4 text-center">Border<br />Management</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-black md:h-[34rem] h-[44rem] overflow-hidden">
          <div className="container h-full">
            <div className="flex md:flex-row flex-col flex-col-reverse flex-wrap w-full md:h-full">
              <div className="md:w-1/2 w-full flex items-center md:mt-0 mt-[30rem]">
                <h5 className="text-white lg:text-heading-sm text-heading-xs font-medium xl:pr-[16rem] lg:pr-[10rem] md:pr-[2rem] pr-[4rem] font-euclidMedium">We collaborate with organisations from both the private and public sectors</h5>
              </div>
              <div className="md:w-1/2 w-full relative">
                <img src={NwIllustration} alt="NwIllustration" className="absolute right-0 md:my-auto md:top-0 md:bottom-0 hover:scale-150 duration-300 transition-all cursor-pointer" />
              </div>
            </div>
          </div>
        </section>
        <section className="bg-gray-400 py-20">
          <h5 className="font-normal text-center md:tracking-[8px]">INVESTMENTS AND PARTNERSHIPS</h5>
          <div className="w-full flex items-center justify-center mt-10">
            <div className="md:w-6/12 w-11/12 text-center">
              <p className="text-body-lg">Newworks Invests and partners with biometrics and identity management startups and businesses. Together, we eliminate redundancies and boost productivity through digital solutions. Our partners are:</p>
            </div>
          </div>
          <div className="flex w-full h-full overflow-x-auto gap-x-12 px-10 flex items-center justify-center mt-20 greyed-icons">
            <img src={NwInnovate} alt="NwInnovate" className="h-16" />
            <img src={NwNetpostSvg} alt="NwNetpostSvg" className="h-16" />
            <img src={NwOis} alt="NwOis" className="h-16" />
            <img src={NwPayfixy} alt="NwPayfixy" className="h-8" />
            <img src={NwSwg} alt="NwSwg" className="h-16" />
            <img src={NwNewEra} alt="NwNewEra" className="h-8" />
          </div>
          <div className="mt-20 md:mb-10 w-full items-center flex justify-center">
            <Button
              state={"SECONDARY"}
              text={"Get Started"}
              onClick={() => handleOpenChange(true)}
              type={"button"}
            />
          </div>
        </section>
        <section className="md:py-20 py-10">
          <div className="container">
            <h4 className="xl:text-heading-xl lg:text-heading-lg md:text-heading-sm md:font-bold text-heading-xs font-bold mb-10 md:text-left text-center font-euclidBold">
              Why Choose Us?
            </h4>
          </div>
          <div className="md:hidden block">
            {WHY_CHOOSE_US.map(w =>
              <div key={w.title} className="mx-6 border-b border-b-[0.5px] pb-5 mt-8">
                <p className="text-body-xl tracking-[4px] mb-4">{w.title}</p>
                <p>{w.body}</p>
              </div>
            )}
          </div>
          <div className="md:block hidden">
            <div className="chooser">
              <div className="container chooser-frame">
                <div className="chooser-item-frame">
                  <img src={NwAutomation} alt="NwAutomation" className="lg:h-auto h-[20rem]" />
                  <div className="text-heading-xs info-name">AUTOMATION</div>
                  <div className="info-desc">Our automation solutions are scalable, secure and reliable, helping businesses save time and money while increasing accuracy and productivity.</div>
                  <div className="info-underline"></div>
                </div>
              </div>
            </div>
            <div className="picker">
              <div className="container picker-frame">
                <div className="picker-item-frame">
                  <img src={NwSecurity} alt="NwSecurity" className="lg:h-auto h-[20rem]" />
                  <div className="text-heading-xs info-name">SECURITY</div>
                  <div className="info-desc">Newworks protects organisations from digital intrusions, threats and other malicious activities for organisations, clients and stakeholders.</div>
                  <div className="info-underline"></div>
                </div>
              </div>
            </div>
            <div className="chooser">
              <div className="container chooser-frame">
                <div className="chooser-item-frame">
                  <img src={NwSustainability} alt="NwSustainability" className="lg:h-auto h-[20rem]" />
                  <div className="text-heading-xs info-name">SUSTAINABILITY</div>
                  <div className="info-desc">We offer sustainable services that drive growth, mitigate costs and risks, enhance resilience, and attain long-term financial prosperity in your business.</div>
                  <div className="info-underline"></div>
                </div>
              </div>
            </div>
            <div className="picker">
              <div className="container picker-frame">
                <div className="picker-item-frame">
                  <img src={NwInfrastructure} alt="NwInfrastructure" className="lg:h-auto h-[20rem]" />
                  <div className="text-heading-xs info-name">INFRASTRUCTURE</div>
                  <div className="info-desc">Choose between on-premises and cloud-based infrastructure, depending on your specific needs. We leverage proven methodologies to ensure long-term success</div>
                  <div className="info-underline"></div>
                </div>
              </div>
            </div>
            <div className="chooser">
              <div className="container chooser-frame">
                <div className="chooser-item-frame">
                  <img src={NwTechInnovation} alt="NwTechInnovation" className="lg:h-auto h-[20rem]" />
                  <div className="text-heading-xs info-name">TECHNOLOGY INNOVATION</div>
                  <div className="info-desc">Our technology solutions are innovated from scratch to cater to your IT needs. We offer comprehensive support from installation to troubleshooting, maximising the value of your investments.</div>
                  <div className="info-underline"></div>
                </div>
              </div>
            </div>
            <div className="picker">
              <div className="container picker-frame">
                <div className="picker-item-frame">
                  <img src={NwCustomerExperience} alt="NwCustomerExperience" className="lg:h-auto h-[20rem]" />
                  <div className="text-heading-xs info-name">CUSTOMER EXPERIENCE</div>
                  <div className="info-desc">Our services enhance your customer experience by providing simple and effective solutions that prioritise your customers' needs.</div>
                  <div className="info-underline"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="mt-20 mb-10 w-full items-center flex justify-center">
              <Button
                state={"SECONDARY"}
                text={"Get Started"}
                onClick={() => handleOpenChange(true)}
                type={"button"}
              />
            </div>
          </div>
        </section>
      </div>
      <Modal
        open={open}
        closable={false}
        footer={null}
        onCancel={() => handleOpenChange(false)}
        className={"cookie-modal"}
        width={780}
      >
        {content}
      </Modal>
    </Main>
  );
};

export default Home;