import React from "react";
import { Form, Input, notification } from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import NwClose from "../assets/imgs/nw-close.png";
import FieldContainer from "./Inputs/FIeldContainer";
import Button from "./Button";

const { TextArea } = Input;

const values = {
  firstName: "",
  lastName: "",
  organization: "",
  country: "",
  email: "",
  phoneNumber: "",
  message: "",
};

const GetStarted = ({ handleClose }) => {
  const [form] = Form.useForm();

  const createUser = (values, { resetForm }) => {
    notification.success({
      message: "Success",
      description: "Message Sent Successfully!",
    });
    resetForm();
    form.resetFields();
    handleClose();
  };

  return (
    <div className="w-full">
      <div onClick={handleClose} className="absolute cursor-pointer sm:h-16 sm:w-16 h-12 w-12 bg-secondary-500 sm:top-[-1.5rem] sm:right-[-1.5rem] top-[-1rem] right-[-1rem] rounded-full flex items-center justify-center"><img alt="NwClose" src={NwClose} className="sm:h-5 h-4" /></div>
      <div className="flex gap-x-4 items-center cursor-pointer sm:text-heading-xl text-heading-md font-euclidBold text-black mb-4">
        Contact Us
      </div>
      <div className={"mt-10 w-full flex justify-center"}>
        <div className={"w-full"}>
          <Formik
            initialValues={values}
            onSubmit={createUser}
            enableReinitialize={true}
            validationSchema={validator}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onFinish={handleSubmit} form={form}>
                <div className="grid sm:grid-cols-2 gap-x-8">
                  <div className={"sm:mb-8 mb-4"}>
                    <div
                      className={
                        "text-left text-gray-600 font-normal text-md-regular mb-2"
                      }
                    >
                      <p>First Name</p>
                    </div>
                    <FieldContainer iconPlacementCss={"iconCss"}>
                      <Form.Item
                        name={"firstName"}
                        help={
                          touched.firstName && errors.firstName
                            ? errors.firstName
                            : ""
                        }
                        validateStatus={
                          touched.firstName && errors.firstName
                            ? "error"
                            : "success"
                        }
                      >
                        <Input
                          type={"text"}
                          className={"form-field_input_2"}
                          placeholder={"Enter First Name"}
                          value={values?.firstName || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Form.Item>
                    </FieldContainer>
                  </div>
                  <div className={"sm:mb-8 mb-4"}>
                    <div
                      className={
                        "text-left text-gray-600 font-normal text-md-regular mb-2"
                      }
                    >
                      <p>Last Name</p>
                    </div>
                    <FieldContainer iconPlacementCss={"iconCss"}>
                      <Form.Item
                        name={"lastName"}
                        help={
                          touched.lastName && errors.lastName
                            ? errors.lastName
                            : ""
                        }
                        validateStatus={
                          touched.lastName && errors.lastName
                            ? "error"
                            : "success"
                        }
                      >
                        <Input
                          type={"text"}
                          className={"form-field_input_2"}
                          placeholder={"Enter Last Name"}
                          value={values?.lastName || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Form.Item>
                    </FieldContainer>
                  </div>
                  <div className={"sm:mb-8 mb-4"}>
                    <div
                      className={
                        "text-left text-gray-600 font-normal text-md-regular mb-2"
                      }
                    >
                      <p>Name of organization/Company</p>
                    </div>
                    <FieldContainer iconPlacementCss={"iconCss"}>
                      <Form.Item
                        name={"organization"}
                        help={
                          touched.organization && errors.organization
                            ? errors.organization
                            : ""
                        }
                        validateStatus={
                          touched.organization && errors.organization
                            ? "error"
                            : "success"
                        }
                      >
                        <Input
                          type={"text"}
                          className={"form-field_input_2"}
                          placeholder={"Enter Name of organization/Company"}
                          value={values?.organization || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Form.Item>
                    </FieldContainer>
                  </div>
                  <div className={"sm:mb-8 mb-4"}>
                    <div
                      className={
                        "text-left text-gray-600 font-normal text-md-regular mb-2"
                      }
                    >
                      <p>Country</p>
                    </div>
                    <FieldContainer iconPlacementCss={"iconCss"}>
                      <Form.Item
                        name={"country"}
                        help={
                          touched.country && errors.country
                            ? errors.country
                            : ""
                        }
                        validateStatus={
                          touched.country && errors.country
                            ? "error"
                            : "success"
                        }
                      >
                        <Input
                          type={"text"}
                          className={"form-field_input_2"}
                          placeholder={"Enter Country"}
                          value={values?.country || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Form.Item>
                    </FieldContainer>
                  </div>
                  <div className={"sm:mb-8 mb-4"}>
                    <div
                      className={
                        "text-left text-gray-600 font-normal text-md-regular mb-2"
                      }
                    >
                      <p>Email</p>
                    </div>
                    <FieldContainer iconPlacementCss={"iconCss"}>
                      <Form.Item
                        name={"email"}
                        help={touched.email && errors.email ? errors.email : ""}
                        validateStatus={
                          touched.email && errors.email ? "error" : "success"
                        }
                      >
                        <Input
                          type={"email"}
                          className={"form-field_input_2"}
                          placeholder={"Enter Email"}
                          value={values?.email || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Form.Item>
                    </FieldContainer>
                  </div>
                  <div className={"sm:mb-8 mb-4"}>
                    <div
                      className={
                        "text-left text-gray-600 font-normal text-md-regular mb-2"
                      }
                    >
                      <p>Phone Number</p>
                    </div>
                    <FieldContainer iconPlacementCss={"iconCss"}>
                      <Form.Item
                        name={"phoneNumber"}
                        help={
                          touched.phoneNumber && errors.phoneNumber
                            ? errors.phoneNumber
                            : ""
                        }
                        validateStatus={
                          touched.phoneNumber && errors.phoneNumber
                            ? "error"
                            : "success"
                        }
                      >
                        <Input
                          type={"tel"}
                          className={"form-field_input_2"}
                          placeholder={"E.g 07012345678"}
                          value={values?.phoneNumber || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          maxLength={11}
                        />
                      </Form.Item>
                    </FieldContainer>
                  </div>
                </div>
                <div className={"sm:mb-8 mb-4"}>
                  <div
                    className={
                      "text-left text-gray-600 font-normal text-md-regular mb-2"
                    }
                  >
                    <p>Your Message</p>
                  </div>
                  <FieldContainer iconPlacementCss={"iconCss"}>
                    <Form.Item
                      name={"message"}
                      help={
                        touched.message && errors.message
                          ? errors.message
                          : ""
                      }
                      validateStatus={
                        touched.message && errors.message
                          ? "error"
                          : "success"
                      }
                    >
                      <TextArea
                        className={"form-field_input_2"}
                        placeholder={"Enter Your Message"}
                        rows={4}
                        value={values?.message || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Form.Item>
                  </FieldContainer>
                </div>
                <div className={"mb-6 mt-16 flex justify-center"}>
                  <Button
                    state={"SECONDARY"}
                    text={"Send Message"}
                    type={"submit"}
                    cssClass={"xl:!text-[14px] !text-[12px]"}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const validator = yup.object().shape({
  firstName: yup
    .string()
    .required("First Name is required"),
  lastName: yup
    .string()
    .required("Last Name is required"),
  organization: yup
    .string()
    .required("Name of organization/Company is required"),
  country: yup
    .string()
    .required("Country is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phoneNumber: yup
    .string()
    .min(11, "Phone Number should 11 characters")
    .required("Phone Number is required"),
  message: yup
    .string()
    .required("Your message is required"),
});

export default GetStarted;
