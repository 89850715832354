import React from "react";
import { Helmet } from 'react-helmet';
import Main from "../../layout/Main";
import NwRobot from "../../assets/imgs/nw-robot.png";
import NwPointMap from "../../assets/icons/nw-point-map.svg";
import "./styles.scss";

const ContactUs = () => {
  return (
    <Main>
      <Helmet>
        <title>Contact | Newworks</title>
        <meta name="keywords" content="New Era" />
      </Helmet>
      <div className={"xl:h-[65rem] lg:h-[52rem] md:h-[38rem] h-[60rem]"}>
        <section className="sm:pt-32 pt-24 container relative">
          <div className="flex flex-wrap">
            <div className="md:w-6/12 w-full">
              <h4 className="xl:text-heading-xl lg:text-heading-lg md:text-heading-sm md:font-bold text-heading-xs font-bold">
                <span className="text-black font-euclidBold">Contact Us</span><span className="text-main-color font-euclidBold">.</span>
              </h4>
            </div>
            <div className="w-full xl:mt-14 lg:mt-8 mt-4">
              <div className="xl:w-3/12 md:w-2/12 w-3/12 border-[0.8px] border-t-black"></div>
            </div>
            <div className="w-full mt-4">
              <div className="md:w-3/12 w-7/12">
                <p className="font-euclidMedium">A: No 5 Onitsha Crescent Area 11, Garki Abuja, Nigeria</p>
                <p className="mt-8 font-euclidMedium">T: +234 708 579 1804</p>
                <p className="mt-8 font-euclidMedium">E: info@newworkssolution.com</p>
              </div>
            </div>
            <div className="xl:mt-[8rem] lg:mt-[4rem] md:block hidden">
              <img src={NwPointMap} alt="NwPointMap" className="xl:h-auto lg:h-[20rem] h-[15rem]" />
            </div>
            <img src={NwRobot} alt="NwRobot" className="xl:h-[65rem] lg:h-[52rem] h-[38rem] absolute xl:right-[-4rem] right-[0rem] md:top-0 top-[22rem]" />
          </div>
        </section>
      </div>
    </Main>
  );
};

export default ContactUs;