import React from "react";
import NwRc from "../assets/imgs/nw-rc.png";
import NwRcb from "../assets/imgs/nw-rcb.png";
import NwClose from "../assets/imgs/nw-close.png";

const CookieSettings = ({ handleClose, handleAccept, handleDecline }) => {
  return (
    <div className="w-full">
      <div onClick={handleClose} className="absolute cursor-pointer sm:h-16 sm:w-16 h-12 w-12 bg-secondary-500 sm:top-[-1.5rem] sm:right-[-1.5rem] top-[-1rem] right-[-1rem] rounded-full flex items-center justify-center"><img alt="NwClose" src={NwClose} className="sm:h-5 h-4" /></div>
      <div className="flex gap-x-4 items-center cursor-pointer sm:text-heading-xs text-body-2xl font-euclidSemibold text-[#0D2359] mb-4">
        Privacy Preference Center
      </div>
      <p className="text-black sm:text-body-lg text-body-md mb-8">When you visit websites, they may store or retrieve data in your browser. This storage is often necessary for the basic functionality of the website. The storage may be used for marketing, analytics, and personalization of the site, such as storing your preferences. Privacy is important to us, so you have the option of disabling certain types of storage that may not be necessary for the basic functioning of the website. Blocking categories may impact your experience on the website.</p>
      <div className="sm:flex sm:justify-start justify-between gap-x-4 mb-12">
        <button onClick={handleDecline} className="sm:w-auto w-full rounded-[33px] px-5 py-3 border-[2px] border-primary-500 text-primary-500 flex items-center gap-x-2 sm:justify-left justify-between sm:mb-0 mb-4">Reject all cookies <img alt="NwRcb" src={NwRcb} className="h-4" /></button>
        <button onClick={handleAccept} className="sm:w-auto w-full rounded-[33px] px-5 py-3 bg-primary-500 text-white flex items-center gap-x-2 sm:justify-left justify-between">Allow all cookies<img alt="NwRc" src={NwRc} className="h-4" /></button>
      </div>
      <div className="flex gap-x-4 items-center cursor-pointer sm:text-heading-xs text-body-2xl font-euclidSemibold text-[#0D2359] mb-4">
        Manage Consent Preferences by Category
      </div>
      <div className="mb-5 border-b border-b-[1px] pb-3 border-b-[#dddde6]">
        <div className="flex justify-between mb-2">
          <p className="sm:text-body-lg text-body-md font-euclidSemibold text-primary-500">Essential</p>
          <p className="sm:text-body-lg text-body-md font-euclidSemibold text-black">Always Active</p>
        </div>
        <p className="text-black sm:text-body-lg text-body-md">Manage Consent Preferences by Category</p>
      </div>
      <div className="mb-5 border-b border-b-[1px] pb-3 border-b-[#dddde6]">
        <div className="flex justify-between mb-2">
          <p className="sm:text-body-lg text-body-md font-euclidSemibold text-primary-500">Marketing</p>
        </div>
        <p className="text-black sm:text-body-lg text-body-md">These items are used to deliver advertising that is more relevant to you and your interests. They may also be used to limit the number of times you see an advertisement and measure the effectiveness of advertising campaigns. Advertising networks usually place them with the website operator’s permission.</p>
      </div>
      <div className="mb-5 border-b border-b-[1px] pb-3 border-b-[#dddde6]">
        <div className="flex justify-between mb-2">
          <p className="sm:text-body-lg text-body-md font-euclidSemibold text-primary-500">Personalization</p>
        </div>
        <p className="text-black sm:text-body-lg text-body-md">These items allow the website to remember choices you make (such as your user name, language, or the region you are in) and provide enhanced, more personal features. For example, a website may provide you with local weather reports or traffic news by storing data about your current location.</p>
      </div>
      <div className="mb-5">
        <div className="flex justify-between mb-2">
          <p className="sm:text-body-lg text-body-md font-euclidSemibold text-primary-500">Analytics</p>
        </div>
        <p className="text-black sm:text-body-lg text-body-md">These items help the website operator understand how its website performs, how visitors interact with the site, and whether there may be technical issues. This storage type usually doesn’t collect information that identifies a visitor.</p>
      </div>
    </div>
  );
};

export default CookieSettings;
