import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.scss";
import Aos from "aos";
import Home from "./views/Home";
import About from "./views/About";
import ContactUs from "./views/ContactUs";
import CookieSettings from "./components/CookieSettings";
import { Modal } from "antd";
import CookieConsent from "react-cookie-consent";

function App() {
  const [open, setOpen] = useState(false);
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  useEffect(() => {
    Aos.init({
      offset: 200,
      duration: 1000,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  const handleAccept = () => {
    setOpen(false);
    const customAccept = document.getElementById("customAccept");
    customAccept.click();
  };

  const handleDecline = () => {
    setOpen(false);
    const customDecline = document.getElementById("customDecline");
    customDecline.click();
  };

  const infoContent = (
    <CookieSettings
      handleClose={() => handleOpenChange(false)}
      handleAccept={handleAccept}
      handleDecline={handleDecline}
    />
  );

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<ContactUs />} />
        </Routes>
      </Router>
      <CookieConsent
        location="bottom"
        buttonText={
          <>
            <span className="arrow"></span>
            <span className="absolute">Accept</span>
          </>
        }
        cookieName="myAwesomeCookieName2"
        style={{
          background: "#294093 0% 0% no-repeat padding-box",
          width: "90%",
          margin: "auto",
          left: "0",
          right: "0",
          top: "10rem!important",
        }}
        buttonClasses={"consent-button"}
        buttonWrapperClasses={
          "relative w-[9.5rem] sm:right-[3rem] right-[-60%] sm:bottom-[0rem] bottom-[1rem]"
        }
        disableButtonStyles={true}
        expires={150}
        enableDeclineButton={true}
        declineButtonClasses={"hidden"}
        buttonId="customAccept"
        declineButtonId="customDecline"
      >
        <div className="sm:px-[3rem] sm:py-[1rem] flex flex-wrap items-center justify-between">
          <div className="text-white font-medium sm:w-9/12 sm:mb-0 mb-10">
            By clicking Accept, you agree to the storing of cookies on your
            device to enhance site navigation, analyse site usage, and assist in
            our marketing efforts. View our Privacy Policy for more information.
          </div>
          <div
            onClick={() => handleOpenChange(true)}
            className="text-white mr-6 border-b border-b-white cursor-pointer sm:block hidden"
          >
            Preferences
          </div>
          <div
            onClick={() => handleOpenChange(true)}
            className="text-white mr-6 border-b border-b-white cursor-pointer sm:hidden block absolute left-[1rem] bottom-[2rem]"
          >
            Preferences
          </div>
        </div>
      </CookieConsent>
      <Modal
        open={open}
        closable={false}
        footer={null}
        onCancel={() => handleOpenChange(false)}
        className={"cookie-modal"}
        width={780}
      >
        {infoContent}
      </Modal>
    </div>
  );
}

export default App;
