const navs = [
  {
    name: "Home",
    route: "/",
    id: "home",
  },
  {
    name: "Products and Services",
    route: "/services-and-products",
    id: "servicesAndProducts",
  },
  // {
  //   name: "Industries",
  //   route: "/industries",
  //   id: "industries",
  // },
  {
    name: "About Us",
    route: "/about",
    id: "aboutUs",
  },
  {
    name: "Contact Us",
    route: "/contact",
    id: "contactUs",
  },
];

export { navs };
