/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import "./styles.scss";
import NwLogoLight from "../../assets/imgs/nw-logo-light.png";
import NwMag from "../../assets/icons/nw-mag.svg";
import NwRightChevron from "../../assets/imgs/nw-right-chevron.png"
import { SOCIAL_ICONS } from "../../utils/footer";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleClickScroll = (e, id, route) => {
    if (id === "servicesAndProducts" || id === "industries") {
      e.preventDefault();
      if (location.pathname === "/about" || location.pathname === "/contact")
        navigate("/#" + id);
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else if (id === "home" && route === "/") {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <footer className={" main-bg-color sm:pb-10 pb-4"}>
      <div className={"container lg:pt-24 pt-2"}>
        <div className={"w-full grid lg:grid-cols-11 lg:gap-12 grid-cols-1 lg:text-left text-center"}>
          <div className={"w-full lg:col-span-3"}>
            <div className="lg:block flex flex-col items-center lg:mt-0 mt-10">
              <a href={"/#"}>
                <img
                  className={"lg:h-auto h-20"}
                  src={NwLogoLight}
                  alt={"Logo"}
                />
              </a>
            </div>
            {/* <div className={"mt-6 lg:mt-12 lg:block hidden"}>
              <div className={"sm:mr-10 mr-0 sm:mt-0 mt-4 mb-8"}>
                <ul className={"flex gap-x-4"}>
                  {SOCIAL_ICONS.map((si, i) => (
                    <a
                      key={i + "icon"}
                      href={si.url}
                      className={"footer-icon"}
                    >
                      <img alt={"instagram"} src={si.icon} />
                    </a>
                  ))}
                </ul>
              </div>
            </div> */}
          </div>
          <div className={"w-full lg:mt-0 mt-20 lg:col-span-2 lg:pl-6"}>
            <div className="text-body-md text-body-lg">
              <NavLink to={"/"} onClick={(e) => handleClickScroll(e, "home", "/")}>
                <p className="text-white font-medium">Home</p>
              </NavLink>
              <NavLink to={"#/"} onClick={(e) => handleClickScroll(e, "servicesAndProducts", "/services-and-products")}>
                <p className="text-white mt-2 font-medium">Services and Products</p>
              </NavLink>
              <NavLink to={"#/"} onClick={(e) => handleClickScroll(e, "industries", "/industries")}>
                <p className="text-white mt-2 font-medium">Industries</p>
              </NavLink>
              <NavLink to={"/about"} onClick={(e) => handleClickScroll(e, "aboutUs", "/about")}>
                <p className="text-white mt-2 font-medium">About Us</p>
              </NavLink>
            </div>
          </div>
          <div className={"w-full lg:mt-0 mt-10 lg:col-span-3 lg:pl-10"}>
            <p className={"font-light text-body-lg text-white tracking-[5px]"}>
              CONTACT US
            </p>
            <div className="text-body-md text-body-lg mt-4">
              <p className="text-white font-medium">
              
              <Link
                    href="tel:+2348163011110"
                  >
                   +234 816 3011 110
                  </Link>
              </p>
              <p className="text-white mt-4 font-medium">
              
              <Link
                    href="mailto:info@newworkssolution.com"
                  >
                    info@newworkssolution.com
                  </Link>
              </p>
              <p className="text-white mt-4 font-medium">No 5 Onitsha Crescent Area 11, Garki Abuja, Nigeria</p>
            </div>
          </div>
          <div className={"w-full lg:mt-0 mt-10 lg:col-span-3 lg:block flex justify-center"}>
            <div className="lg:w-full md:w-1/2 w-full">
              <p className={"font-light text-body-lg text-white tracking-[5px]"}>
                NEWSLETTER
              </p>
              <div className="text-body-md text-body-lg mt-4">
                <p className="text-white font-semibold text-body-lg">No sales pitches - just valuable insights.</p>
              </div>
              <div className="flex items-center relative">
                <input type="email" className="bg-transparent border-b-[1px] border-b-white w-full my-6" placeholder="Email Address" />
                <img src={NwRightChevron} alt="icon" className="h-4 absolute right-0 top-[2.5rem]" />
              </div>
              <div className={"mt-6 lg:hidden flex justify-center"}>
                <div className={"mt-4 mb-8"}>
                  <ul className={"flex gap-x-6"}>
                    {SOCIAL_ICONS.map((si, i) => (
                      <a
                        key={i + "icon"}
                        href={si.url}
                        className={"footer-icon-mobile"}
                      >
                        <img alt={"instagram"} src={si.icon} />
                      </a>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className={"w-full mt-10 justify-center lg:hidden flex"}>
            <div className="lg:w-full md:w-1/2 w-full">
              <p className={"font-light text-body-lg text-white tracking-[5px]"}>
                LOCATION
              </p>
              <div className="flex items-center justify-center gap-x-1 mt-2">
                <p className="text-white text-body-md">Nigeria |</p>
                <p className="text-white text-body-md">Ghana |</p>
                <p className="text-white text-body-md">United Kingdom |</p>
                <p className="text-white text-body-md">Ethiopia</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-10">
        <div className={"border-t container mt-6 border-t-primary-100 xl:!px-0 pt-2"}>
          <div className="lg:grid lg:grid-cols-2 items-center lg:justify-between flex flex-col lg:my-0 my-10">
            <p className="text-white font-light text-body-xs">Copyright © 2023 Newworks Solutions</p>
            <div className="text-right flex items-center lg:justify-end gap-x-4 lg:my-0 my-10">
              <p className="text-white font-light text-body-xs">An affiliate of</p>
              <a target="_blank" href="https://maggrouplimited.com">
                <img
                  className={"lg:h-auto h-10"}
                  src={NwMag}
                  alt={"Logo"}
                />
              </a>
            </div>
          </div>
          {/* <div className="lg:grid lg:grid-cols-7 items-center lg:justify-between flex flex-col lg:my-0 my-10">
            <p className="col-span-2 text-white font-light text-body-xs">Copyright © 2023 Newworks Solutions</p>
            <div className="lg:flex col-span-3 hidden items-center justify-center gap-x-4">
              <p className="text-white font-light tracking-[5px]">LOCATION</p>
              <p className="text-white font-medium text-body-sm">Nigeria |</p>
              <p className="text-white font-medium text-body-sm">Ghana |</p>
              <p className="text-white font-medium text-body-sm">United Kingdom |</p>
              <p className="text-white font-medium text-body-sm">Ethiopia</p>
            </div>
            <div className="lg:col-span-2 text-right flex items-center lg:justify-end gap-x-4 lg:my-0 my-10">
              <p className="text-white font-light text-body-xs">An affiliate of</p>
              <a target="_blank" href="https://maggrouplimited.com">
                <img
                  className={"lg:h-auto h-10"}
                  src={NwMag}
                  alt={"Logo"}
                />
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
