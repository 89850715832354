import NwInstagram from "../assets/icons/nw-instagram.svg";
import NwTwitter from "../assets/icons/nw-twitter.svg";
import NwFacebook from "../assets/icons/nw-facebook.svg";
import NwLinkedIn from "../assets/icons/nw-linkedin.svg";
const SOCIAL_ICONS = [
  {
    icon: NwInstagram,
    url: "/#",
  },
  {
    icon: NwTwitter,
    url: "/#",
  },
  {
    icon: NwFacebook,
    url: "/#",
  },
  {
    icon: NwLinkedIn,
    url: "/#",
  },
];

export { SOCIAL_ICONS };
