const WHY_CHOOSE_US = [
  {
    title: "AUTOMATION",
    body: "Our automation solutions are scalable, secure and reliable, helping you save time and money while increasing accuracy and productivity.",
  },
  {
    title: "SECURITY",
    body: "Newworks protects you from digital intrusions, threats and other malicious activities. Confidential information is kept secure and private for your organisation, clients, and stakeholders.",
  },
  {
    title: "SUSTAINABILITY",
    body: "We offer sustainable services that drive growth, mitigate costs and risks, enhance resilience, and attain long-term financial prosperity in your business.",
  },
  {
    title: "INFRASTRUCTURE",
    body: "Choose between on-premises and cloud-based infrastructure, depending on your specific needs. We leverage proven methodologies to ensure long-term success",
  },
  {
    title: "TECHNOLOGY INNOVATION",
    body: "Our technology solutions are innovated from scratch to cater to your IT needs. We offer comprehensive support from installation to troubleshooting, maximising the value of your investments.",
  },
  {
    title: "CUSTOMER EXPERIENCE",
    body: "Our services enhance your customer experience by providing simple and effective solutions that prioritise your customers' needs.",
  },
];

export { WHY_CHOOSE_US };
